import React from 'react'

function Footer() {
  return (
    
<footer class="text-center text-lg-start  text-muted" style={{background: 'linear-gradient(to right, #7519de , #7519de)'}}>
 
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
   
    <div class="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
    

    
    <div>
      <a href="" class="me-4 text-reset">
      <i class="bi bi-facebook"></i>
      </a>
      <a href="" class="me-4 text-reset">
      <i class="bi bi-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="bi bi-google"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="bi bi-instagram"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="bi bi-linkedin"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="bi bi-github"></i>
      </a>
    </div>
    
  </section>
  
  <section class="">
    <div class="container text-center text-md-start mt-5">
     
      <div class="row mt-3">
        
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3"></i>ezy properties
          </h6>
          <p>
          Ezy Properties, your dedicated real estate companion, simplifies your property ventures with expertise and personalized service. Explore dream homes and smart investments effortlessly with our commitment to excellence. Your journey to exceptional real estate experiences begins with Ezy Properties.
          </p>
        </div>
        
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
         
          <h6 class="text-uppercase fw-bold mb-4">
            Products
          </h6>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
        </div>
        
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
          <p>
            <a href="#!" class="text-reset">text</a>
          </p>
        </div>
        
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p><i class="fas fa-home me-3"></i> guutur,Andhra Pradesh</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            ezyproperties.com
          </p>
          <p><i class="fas fa-phone me-3"></i> 9988776655</p>
          <p><i class="fas fa-print me-3"></i> 9977553322</p>
        </div>
        
      </div>
      
    </div>
  </section>
  
  <div class="text-center p-4" >
    © 2023 Copyright:{"  "}
    <a class="text-reset fw-bold" href="https://nareshit.in/">ezyproperties</a>
  </div>
 
</footer>

  )
}

export default Footer