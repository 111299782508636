import React from 'react'
import Navbar from '../Components/Navbar'
import Banner from '../Components/Home/Banner'
import Updates from '../Components/Home/Updates'
import Footer from '../Components/Footer'


function Home() {
  return (
    <div>
      <div >
         <Navbar />
      </div>
      <div style={{ marginTop:'60px'}}>
         <Banner />
      </div>
      <div>
        <Updates/>
      </div>
      
    
      <div style={{marginTop:'10px'}}>
        <Footer/>
      </div>
      

     
    </div>
  )
}

export default Home