import React from 'react'
import './Card.css'

function Card() {
  return (
    <div className='card border-0' id='card'>
       <div className='c_image' id='c_image'>
          <img src='https://radiantassets.com/wp-content/uploads/2020/05/radiant-appartment.png' alt='oops'/>
       </div>
       <div  className="card-details" id='c_body'>
           <div id='price'><b>Rs 3.7 cr - 4.3 cr </b></div>
           <div id="title">Shangrila Abbham</div>
           <div id="area">Kismathpur, Hyderabad</div>
           <div id='keys'><b>4BHK</b><b>Completion in Mar, 2026</b></div>
       </div>
       <div id='c_circle'>
             <i class="bi bi-house-heart-fill icon"></i>
       </div>
    </div>
  )
}

export default Card