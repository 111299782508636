import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'


function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg  fixed-top" style={{background: 'linear-gradient(to right, #7519de , #7519de)'}}>
      <div style={{marginLeft:"20px"}}>
        <b>
            <Link  to={'/'}class="navbar-brand" href="#">
            <img style={{borderRadius:50,marginRight:'10px'}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9a8YfRB3IYZGhDHoiumm8T6OMqi2f13a6Pg&usqp=CAU" alt="Logo" width="50" height="50" class="d-inock alline-blign-text-mid"></img>    
            EZY PROPERTIES
            </Link>
        </b>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvasLg" aria-controls="navbarOffcanvasLg" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvasLg" aria-labelledby="navbarOffcanvasLgLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">ezy properties</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <Link to="/posting" class="nav-link active btn btn btn-light" aria-current="page" href="#"><b><i class="bi bi-upload"></i>{" "}POSTING</b></Link>
            </li>
            
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Sign in</a></li>
                <li><a class="dropdown-item" href="#">Sign out</a></li>
                <li>
                  <hr class="dropdown-divider"/>
                </li>
                
              </ul>
            </li>
            <li class="nav-item" style={{fontSize:'20px'}}>
              <i class="bi bi-person-circle nav-link"></i>
             
            </li>
          </ul>
         
        </div>
      </div>
    </nav>
  )
}

export default Navbar