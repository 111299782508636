import React from 'react'
import './Banner.css'

function Banner() {
  return (
    <div className="total_container">
        <div className='banner_container'>

            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src="https://myhomebhooja.com/img/Bhooja_Banner_Phase2.jpg" class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item">
                    <img src="https://myhomebhooja.com/img/Bhooja_Banner_Phase2.jpg" class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item">
                    <img src="https://myhomebhooja.com/img/Bhooja_Banner_Phase2.jpg" class="d-block w-100" alt="..."/>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <div id='category_container'  style={{background: 'linear-gradient(to right, #7519de , #7519de)'}}>
                <a id='cate_item' style={{fontSize:'20px'}} ><b><i class="bi bi-buildings"></i>{" "}Buy</b></a>
                <a id='cate_item'style={{fontSize:'20px'}}><b><i class="bi bi-building-fill-add"></i>{" "}Rent</b></a>
                <a id='cate_item' style={{fontSize:'20px'}}><b><i class="bi bi-card-checklist"></i>{" "}Management</b></a>
                <a id='cate_item' style={{fontSize:'20px'}}><b><i class="bi bi-currency-rupee"></i>{" "}Loan</b></a>
                
            </div>
           
        </div>

        <div className='ads_container'> 

            <img src ={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbDFhfSVlQtUDLJGMLfN16gGE72RXNH6zkCoAGX4g0vMblnqvSQgr6-kW_giR5CDJg2gw&usqp=CAU"} alt="oops" />
          
        </div>
        
   </div>
  )
}

export default Banner