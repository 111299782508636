import React from 'react'
import { Link } from 'react-router-dom'
import './Updates.css'
import Card from '../test/Card';
import Hcard from '../test/Hcard';
import Cardthree from '../test/Cardthree';

const fakeRealEstateData = [
  {
    title: 'Luxury Apartment',
    by: 'ABC Developers',
    place: 'City Center',
    price: '$500,000',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3DMoGY9nHcy23lDBqXx_MsZtr-VMo9nlSA&usqp=CAU',
  },
  {
    title: 'Suburban Home',
    by: 'XYZ Builders',
    place: 'Suburbia',
    price: '$350,000',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3DMoGY9nHcy23lDBqXx_MsZtr-VMo9nlSA&usqp=CAU',
  },
  {
    title: 'Oceanfront Villa',
    by: 'Ocean View Estates',
    place: 'Seaside',
    price: '$1,200,000',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3DMoGY9nHcy23lDBqXx_MsZtr-VMo9nlSA&usqp=CAU',
  },
  {
    title: 'Mountain Retreat',
    by: 'Summit Homes',
    place: 'Mountains',
    price: '$800,000',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3DMoGY9nHcy23lDBqXx_MsZtr-VMo9nlSA&usqp=CAU',
  },
  {
    title: 'Downtown Condo',
    by: 'City Living Builders',
    place: 'Downtown',
    price: '$600,000',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3DMoGY9nHcy23lDBqXx_MsZtr-VMo9nlSA&usqp=CAU',
  },
  // Add more fake data as needed
];


function Updates() {

 
  
  return (
    <>
    <div id='box'>
        <h1><b>Latset projects..</b></h1>
        <h6>you can write anything here.....</h6>
        <div id='c_box'>
            {fakeRealEstateData.map((item,index)=>(
              <Card/>
            ))}
        </div>
        <div>
          <Link to="./" className='btn btn-primary'>see all{" "}<i class="bi bi-arrow-right-circle"></i></Link>
        </div>
    </div>

    <div id='box'>
        <h1><b>Latset projects..</b></h1>
        <h6>you can write anything here.....</h6>
        <div id='c_box'>
            {fakeRealEstateData.map((item,index)=>(
              <Cardthree/>
            ))}
        </div>
        <div>
          <Link to="./" className='btn btn-primary mt-2'>see all {" "} <i class="bi bi-arrow-right-circle"></i></Link>
        </div>
    </div>

    <div id='box'>
        <h1><b>Latset projects..</b></h1>
        <h6>you can write anything here.....</h6>
        <div id='c_box'>
            {fakeRealEstateData.map((item,index)=>(
              <Hcard/>
            ))}
        </div>
        <div>
          <Link to="./" className='btn btn-primary mt-2'>see all {" "}<i class="bi bi-arrow-right-circle"></i></Link>
        </div>
    </div>

    


    </>
  )
}

export default Updates