import React from 'react'
import './Hcard.css'

function Hcard() {
  return (
    <div id='hc_box'>
        <div id='hc_body'>
            <div id='hc_img'>
                <img src='https://radiantassets.com/wp-content/uploads/2020/05/radiant-appartment.png' alt='oops'/>  
            </div>
            <div  className="card-details" id='hc_body_details'>
                <div id='price'><b>Rs 3.7 cr - 4.3 cr </b></div>
                <div id="title">Shangrila Abbham</div>
                <div id="area">Kismathpur, Hyderabad</div>
                <div id='keys'><b>4BHK</b>{"|"}<b>Completion in Mar, 2026</b></div>
            </div>
        </div>
    </div>
  )
}

export default Hcard