import React from 'react'
import './Cardthree.css'

function Cardthree() {
  return (
    <div class="card" id='tcard'>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="card-inner">
          <div id='tc_img'>
            <img src='https://radiantassets.com/wp-content/uploads/2020/05/radiant-appartment.png' alt='..'/>
          </div>
          <div id='tc_details'>
            <div id='price'><b>Rs 3.7 cr - 4.3 cr </b></div>
            <div id="title"><b>Shangrila Abbham</b></div>
            <div id="area">Kismathpur, Hyderabad</div>
            <div id='keys'><b>4BHK</b>{"|"}<b>Completion in Mar, 2026</b></div>
        
          </div>
        </div>
    </div>
  )
}

export default Cardthree